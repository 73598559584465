import React from "react";
import { connect as connectFela } from "react-fela";

import fluidValues from "utilities/fluidValues";
import Row from "./row";
import Content from "./content";
import Container from "components/container";
import Details from "./details";
import List from "./list";
import Speakers from "./speakers";
import Form from "components/form";
import { getHexColor } from "theme/colors";

const Wrapper = connectFela((props) => ({
    container: {
        ...fluidValues({
            marginBottom: [60, 120],
        }),
    },
}))(({ rules, styles, children }) => {
    return <div className={styles.container}>{children}</div>;
});

const create = (section, context) => {
    switch (section._type) {
        case "lwp_row":
            return (
                <Wrapper>
                    <Row
                        key={section._rev}
                        withMaxWidth={
                            section.left[0]._type === "form" ||
                            section.right[0]._type === "form"
                        }
                        left={create(section.left[0], {
                            ...context,
                            left: true,
                        })}
                        right={create(section.right[0], {
                            ...context,
                            right: true,
                        })}
                    />
                </Wrapper>
            );
        case "lwp_details":
            return <Details key={section._rev} {...section} />;
        case "lwp_list":
            return context.left || context.right ? (
                <List {...section} />
            ) : (
                <Container flex withContentArea4>
                    <List {...section} />
                </Container>
            );
        case "lwp_speakers":
            return <Speakers {...section} />;
        case "lwp_content":
            return context.left || context.right ? (
                <Content
                    key={section._rev}
                    content={section._rawContentSection}
                />
            ) : (
                <Container flex withContentArea4>
                    <Content
                        key={section._rev}
                        content={section._rawContentSection}
                    />
                </Container>
            );
        case "form":
            return (
                <Form
                    {...section}
                    bgColor={getHexColor(context?.highlightColor, "500")}
                />
            );
        default:
        // Code to execute if no case matches
    }
};

export default create;
