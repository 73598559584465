import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { connect as connectFela } from "react-fela";
import { GatsbyImage as Img } from "gatsby-plugin-image";
import { useSpring, animated } from "react-spring";
import { navigate, useLocation } from "@reach/router";

import H2 from "components/typography/H2";
import P from "components/typography/P";
import colors from "theme/colors";
import fluidValues from "utilities/fluidValues";
import TextInput from "components/TextInput";
import SelectInput from "components/SelectInput";
import SubmitButton from "components/SubmitButton";
import { sendToVicky, sendToCM } from "./service";
import { useExtractHref } from "components/BaseLink";

const isEmailAddress = (value) => {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(value).toLowerCase());
};

export const SignupForm = ({
  rules,
  styles,
  image,
  title,
  thankyoumessage,
  dark,
  cmListID,
  onSubmission,
  buttonText,
  intro,
  fields,
  bgColor,
  destination,
}) => {
  const destinationHref = useExtractHref(destination);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const t = fields.reduce((acc, field) => {
    acc[field.name] = { value: "", error: null };
    return acc;
  }, {});
  const [data, setData] = useState(t);
  const handleChange = (event) => {
    const input = event.target;
    setData((data) => {
      const field = {};
      field[input.name] = { value: input.value, error: "" };
      return { ...data, ...field };
    });
  };
  const resultProps = useSpring({
    opacity: submitted ? 1 : 0,
    config: { tension: 210, friction: 10, clamp: true },
  });

  const handleSubmit = (event) => {
    event.preventDefault();

    // validate required, email format
    let errors = {};

    Object.keys(data).forEach((key) => {
      const dataField = data[key];
      const field = fields.find((f) => f.name == key);

      if (field.required && !dataField.value) {
        errors[key] = {
          value: dataField.value,
          error: "Sorry, we'd like to know this...",
        };
      }

      if (field.type == "email" && !isEmailAddress(dataField.value)) {
        errors[key] = {
          value: dataField.value,
          error: "Are you sure? That email address doesn't seem quite right...",
        };
      }
    });

    if (Object.keys(errors).length > 0) {
      setData((data) => {
        return { ...data, ...errors };
      });
      return false;
    }

    setLoading(true);

    const payload = Object.keys(data).reduce((acc, field) => {
      acc[field] = data[field].value;
      return acc;
    }, {});

    let response = null;
    if (cmListID) {
      payload.cmListID = cmListID;
      response = sendToCM(payload);
    } else {
      payload.subject = typeof document !== "undefined" && document.title;
      payload.page = typeof document !== "undefined" && document.title;
      response = sendToVicky(payload);
    }

    if (destinationHref) {
      navigate(destinationHref);
      return;
    }

    response
      ?.then((data) => {
        setLoading(false);
        setSubmitted(true);
        onSubmission();
      })
      .catch((error) => {
        setLoading(false);
        setError(
          "Something's not right with the info you've provided. Please try again...",
        );
      });
  };
  return (
    <div className={styles.topContainer}>
      {image && <Img image={image.asset.gatsbyImageData} alt={""} />}
      <div className={styles.container}>
        {title && (
          <H2 neo extend={{ styles: rules.title }}>
            {title}
          </H2>
        )}
        {submitted && (
          <animated.div className={styles.result} style={resultProps}>
            <P>{thankyoumessage}</P>
          </animated.div>
        )}
        <form
          noValidate
          method={"POST"}
          onSubmit={(e) => handleSubmit(e)}
          className={styles.form}
        >
          {fields.map((field, index) => {
            return (
              <TextInput
                key={"field" + index}
                onChange={(e) => handleChange(e)}
                title={field.label}
                name={field.name}
                value={data[field.name].value}
                error={data[field.name].error}
                required={field.required}
              />
            );
          })}

          <SubmitButton
            dark={dark}
            extend={{ styles: rules.button }}
            name={"Submit"}
            loading={loading}
            value={buttonText}
          />
          {!error && <div>{error}</div>}
        </form>
      </div>
    </div>
  );
};

/*
 * color={dark ? "#FFFFFF" : null} bgColor={dark ? colors.colorConsole500 : null}
 */
const styles = (props) => ({
  container: {
    position: "relative",
    backgroundColor: props.bgColor
      ? props.bgColor
      : props.dark
        ? colors.colorConsole500
        : colors.colorCanvas100,
    ...fluidValues({
      paddingLeft: [24, 80],
      paddingRight: [24, 80],
      paddingBottom: [48, 90],
    }),
    phoneOnly: {
      paddingTop: 12,
    },
  },
  title: {
    textAlign: "center",
    color: colors.colorConsole500,
    borderColor: colors.colorConsole500,
    extend: {
      condition: props.dark,
      style: {
        color: "#FFFFFF",
        borderColor: "#FFFFFF",
      },
    },
    paddingTop: 32,
  },
  button: {
    margin: "0 auto",
    width: "100%",
    backgroundColor: props.bgColor,
  },
  form: {
    ...fluidValues({
      paddingTop: [0, 50],
    }),

    color: colors.colorConsole500,
    borderColor: colors.colorConsole500,
    "& input[type='text']": {
      color: colors.colorConsole500,
      onFocus: {
        color: colors.colorConsole500,
      },
    },
    "& input[type='email']": {
      color: colors.colorConsole500,
      onFocus: {
        color: colors.colorConsole500,
      },
    },

    extend: {
      condition: props.dark,
      style: {
        color: "#FFFFFF",
        borderColor: "#FFFFFF",
        "& input[type='text']": {
          color: "#FFFFFF",
          onFocus: {
            color: "#FFFFFF",
          },
        },
        "& input[type='email']": {
          color: "#FFFFFF",
          onFocus: {
            color: "#FFFFFF",
          },
        },
      },
    },
  },
  result: {
    alignItems: "center",
    backgroundColor: props.bgColor
      ? props.bgColor
      : props.dark
        ? colors.colorConsole500
        : colors.colorCanvas100,
    boxSizing: "border-box",
    display: "flex",
    height: "100%",
    justifyContent: "center",
    left: 0,
    position: "absolute",
    top: 0,
    width: "100%",
    zIndex: 2,
    ...fluidValues({
      paddingTop: [24, 90],
      paddingLeft: [24, 80],
      paddingRight: [24, 80],
      paddingBottom: [48, 90],
    }),
    color: colors.colorConsole500,
    borderColor: colors.colorConsole500,

    extend: {
      condition: props.dark,
      style: {
        color: "#FFFFFF",
        borderColor: "#FFFFFF",
      },
    },
  },
  topContainer: {
    width: "100%",
    "@media (min-width: 900px)": {
      marginTop: 108,
      maxWidth: 594,
    },
  },
});

export default connectFela(styles)(SignupForm);
