import React from "react";
import BlockContent from "@sanity/block-content-to-react";
import { connect as connectFela } from "react-fela";

import Container from "components/container";
import P from "components/typography/P";
import H2 from "components/typography/H2";
import Ul from "components/typography/UL";

import { commonMarks } from "utilities/serializers";
import { serializers as linkSerializers } from "components/BaseLink.js";

/* Serializers */
const serializers = ({ styles, rules, block }) => {
    return {
        list: (props) => (
            <Ul {...props} type={props.type} extend={{ styles: rules.list }}>
                {props.children}
            </Ul>
        ),
        listItem: (props) => {
            return (
                <P tag={"li"} version={"list"}>
                    {props.children}
                </P>
            );
        },
        types: {
            block: (props) => {
                const style = props.node.style;

                if (style === "title") {
                    return <H2>{props.children}</H2>;
                }
                if (style === "medium" && block === "specs") {
                    return (
                        <P version="medium" extend={{ styles: rules.specs }}>
                            {props.children}
                        </P>
                    );
                }
                if (style === "medium") {
                    return <P version="medium">{props.children}</P>;
                }
                if (style === "normal") {
                    return <P version="medium">{props.children}</P>;
                }

                if (style === "large") {
                    return <P version="large">{props.children}</P>;
                }
                if (style === "xlarge") {
                    return <P version="xlarge">{props.children}</P>;
                }
                return BlockContent.defaultSerializers.types.block(props);
            },
        },
        marks: {
            ...linkSerializers(styles, rules),
            ...commonMarks({ styles, rules }),
        },
    };
};

export default connectFela(
    () => ({
        links: {
            textDecoration: "underline",
        },
    }),
)(({rules, styles, content}) => {
    return (
        <Container extend={{ styles: rules.container }} noPadding>
            <BlockContent
                className={
                    styles.container
                }
                renderContainerOnSingleChild
                blocks={content}
                serializers={serializers({
                    rules,
                    styles,
                })}
                imageOptions={{
                    w: 1263,
                    h: 1016,
                    fit: "max",
                }}
                projectId={
                    process.env.SANITY_PROJECT_ID ||
                        process.env.GATSBY_SANITY_PROJECT_ID
                }
                dataset={
                    process.env.SANITY_DATASET || process.env.GATSBY_SANITY_DATASET
                }
            />
        </Container>
    );
});
