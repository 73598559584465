export const sendToCM = (payload) => {
  return fetch("/.netlify/functions/subscribe-form", {
    method: "POST",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};

export const sendToVicky = (payload) => {
  return fetch("/.netlify/functions/send-form", {
    method: "POST",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};
