// TODO: phone padding of header image
import React from "react";
import { graphql } from "gatsby";
import BlockContent from "@sanity/block-content-to-react";
import { connect as connectFela } from "react-fela";
import { GatsbyImage as Img } from "gatsby-plugin-image";

import Layout from "components/layout";
import P from "components/typography/P";
import H2 from "components/typography/H2";
import Ul from "components/typography/UL";
import { getHexColor } from "theme/colors";

import Header from "./header";
import fluidValues from "utilities/fluidValues";
import { commonMarks } from "utilities/serializers";
import { serializers as linkSerializers } from "components/BaseLink.js";
import Container from "components/container";

import createSection from "./section-factory";

/* Serializers */
const serializers = ({ styles, rules, block }) => {
    return {
        list: (props) => (
            <Ul {...props} type={props.type} extend={{ styles: rules.list }}>
                {props.children}
            </Ul>
        ),
        listItem: (props) => {
            return (
                <P tag={"li"} version={"list"}>
                    {props.children}
                </P>
            );
        },
        types: {
            block: (props) => {
                const style = props.node.style;

                if (style === "title") {
                    return <H2>{props.children}</H2>;
                }
                if (style === "medium" && block === "specs") {
                    return (
                        <P version="medium" extend={{ styles: rules.specs }}>
                            {props.children}
                        </P>
                    );
                }
                if (style === "medium") {
                    return <P version="medium">{props.children}</P>;
                }
                if (style === "normal") {
                    return <P version="body">{props.children}</P>;
                }
                return BlockContent.defaultSerializers.types.block(props);
            },
        },
        marks: {
            ...linkSerializers(styles, rules),
            ...commonMarks({ styles, rules }),
        },
    };
};

export default connectFela(
    ({
        data: {
            sanityLandingWebinarPage: { highlightColor, color, image },
        },
    }) => ({
        headerImage: {
            width: "100%",
        },
        headerImageInnerContainer: {
            // maxWidth: 1126,
            phoneOnly: {
                padding: "0px !important",
            },
        },
        headerImageContainer: {
            background: `linear-gradient(to bottom, ${getHexColor(highlightColor, "500")} 50%, transparent 50%)`,
            ...fluidValues({
                marginBottom: [60, 120],
            }),
        },
        sectionsContainer: {
            backgroundColor: "transparent",
            boxSizing: "border-box",
            marginLeft: "auto",
            marginRight: "auto",
            maxWidth: 1440,
            width: "100%",
            positon: "relative",
        },
        sectionWrapper: {
            maxWidth: 1440,
        },
        links: {
            textDecoration: "underline",
        },
    }),
)(({
    path,
    pageContext,
    rules,
    styles,
    data: {
        jobs,
        settings,
        sanityLandingWebinarPage: {
            breadcrumb,
            category,
            color,
            headerImage,
            intro,
            meta,
            sections,
            title,
            highlightColor,
        },
    },
}) => {
    return (
        <Layout
            pageColor={getHexColor(color, "100")}
            linkColor={getHexColor(color, "500")}
            title={title}
            slug={{ current: path }}
            meta={meta || {}}
            settings={settings}
            jobs={jobs}
            country={pageContext.country}
            pageContext={pageContext}
        >
            <Header
                bgColor={getHexColor(highlightColor, "500")}
                breadcrumb={breadcrumb}
                category={category}
                title={title}
            />

            {headerImage && (
                <div className={styles.headerImageContainer}>
                    <Container
                        extend={{ styles: rules.headerImageInnerContainer }}
                        withContentArea4
                    >
                        <Img
                            image={headerImage.asset?.gatsbyImageData}
                            className={styles.headerImage}
                            alt={title}
                        />
                    </Container>
                </div>
            )}

            <div className={styles.sectionsContainer}>
                {sections.length > 0 &&
                    sections.map((section, index) => {
                        return (
                            <div key={index} className={styles.sectionWrapper}>
                                {createSection(section, { highlightColor: highlightColor })}
                            </div>
                        );
                    })}
            </div>
        </Layout>
    );
});

export const query = graphql`
    query ($country: String!, $slug: String!) {
        settings: sanitySettings(countrySet: { eq: $country }) {
            ...Settings
        }

        jobs: allSanityJob(filter: { countrySet: { eq: $country } }) {
            edges {
                node {
                    ...Jobs
                }
            }
        }

        sanityLandingWebinarPage(
            countrySet: { eq: $country }
            slug: { current: { eq: $slug } }
        ) {
            countrySet
            title
            meta {
                title
                description
                other {
                    property
                    content
                }
            }
            headerImage {
                asset {
                    gatsbyImageData(
                        layout: CONSTRAINED
                        width: 1440
                        placeholder: BLURRED
                    )
                }
            }
            slug {
                current
            }
            color
            highlightColor

            category
            breadcrumb
            sections {
                ... on SanityLwpContent {
                    _type
                    _rawContentSection
                }
                ... on SanityLwpSpeakers {
                    _type
                    speakers {
                        photo {
                            asset {
                                gatsbyImageData(layout: CONSTRAINED, width: 108)
                            }
                        }
                        role
                        name
                        organisation
                        bio
                        socials {
                            name
                            link
                        }
                    }
                }
                ... on SanityLwpRow {
                    _type
                    left {
                        ... on SanityLwpList {
                            _type
                            title
                            points
                        }
                        ... on SanityLwpContent {
                            _type
                            _rawContentSection(
                                resolveReferences: { maxDepth: 10 }
                            )
                        }
                        ... on SanityForm {
                            _type
                            thankyoumessage
                            title
                            intro
                            buttonText
                            destination {
                                ...sanityLinkFrag
                            }
                            fields {
                                name
                                label
                                required
                                type
                            }
                        }
                        ... on SanityLwpDetails {
                            _type
                            date
                            time
                            speakers
                            venue
                        }
                    }

                    right {
                        ... on SanityLwpList {
                            _type
                            title
                            points
                        }
                        ... on SanityLwpContent {
                            _type
                            _rawContentSection(
                                resolveReferences: { maxDepth: 10 }
                            )
                        }
                        ... on SanityForm {
                            _type
                            thankyoumessage
                            title
                            intro
                            buttonText
                            destination {
                                ...sanityLinkFrag
                            }
                            fields {
                                name
                                label
                                required
                                type
                            }
                        }
                        ... on SanityLwpDetails {
                            _type
                            date
                            time
                            speakers
                            venue
                        }
                    }
                }
            }
        }
    }
`;
