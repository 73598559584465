import React from "react";
import { connect as connectFela } from "react-fela";

import Container from "components/container";
import H2 from "components/typography/H2";
import H4 from "components/typography/H4";
import Img from "components/Img";
import P from "components/typography/P";
import Social from "components/Social";
import fluidValues from "utilities/fluidValues";

const Speaker = connectFela((props) => ({
    photo: {
        ...fluidValues({
            width: [170, 200],
            height: [170, 200],
            marginLeft: [24, 77],
        }),
        borderRadius: "50%",
        overflow: "hidden",
        "@media (max-width: 450px)": {
            marginLeft: 0,
        },
    },
    photoContainer: {
        position: "relative",
        "@media (max-width: 450px)": {
            display: "flex",
            justifyContent: "center",
        },
    },
    h4s: {
        allUp: {
            marginTop: 0,
            marginBottom: 0,
        },
    },
    ps: {
        allUp: {
            marginBottom: 0,
        },
    },
    row: {
        display: "flex",
        justifyContent: "space-between",
        ...fluidValues({
            gap: [12, 53],
            marginBottom: [32, 78],
        }),
        position: "relative",
        onBefore: {
            content: "''",
            display: "block",
            background: "black",
            position: "absolute",
            top: 0,
            bottom: 0,
            ...fluidValues({
                width: [12, 24],
            }),
        },
        "@media (max-width: 767px)": {
            flexWrap: "wrap",
        },
        "@media (max-width: 450px)": {
            flexDirection: "column",
            onBefore: {
                display: "none",
            },
        },
    },
    bio: {
        maxWidth: 438,
        allUp: {
            marginBottom: 0,
        },
    },
    details: {
        display: "flex",
        flexDirection: "column",
        flex: "1 1 35%",
        maxHeight: 200,
        "@media (max-width: 450px)": {
            ...fluidValues({
                marginLeft: [36, 77],
            }),
            position: "relative",
            onBefore: {
                content: "''",
                display: "block",
                background: "black",
                position: "absolute",
                top: 0,
                bottom: -12,
                ...fluidValues({
                    width: [12, 24],
                    left: [-36, -77],
                }),
            },
        },
    },
    bioContainer: {
        "@media (max-width: 450px)": {
            position: "relative",
            onBefore: {
                content: "''",
                display: "block",
                background: "black",
                position: "absolute",
                top: -12,
                bottom: 0,
                ...fluidValues({
                    width: [12, 24],
                    left: [-36, -77],
                }),
            },
        },
        "@media (max-width: 767px)": {
            ...fluidValues({
                marginLeft: [36, 77],
            }),
        },
    },
    mobileSocials: {
        marginTop: "auto",
        "@media (min-width: 451px)": {
            display: "none",
        },
    },
    socials: {
        marginTop: "1rem",
        "@media (max-width: 450px)": {
            display: "none",
        },
    },
}))(({ rules, styles, name, role, organisation, bio, socials, photo }) => {
    return (
        <div className={styles.row}>
            <div className={styles.photoContainer}>
                <div className={styles.mobileSocials}>
                    {socials &&
                        socials.map((social, index) => {
                            return (
                                <Social
                                    extend={{ styles: rules.social }}
                                    key={`profile-social-${index}`}
                                    bgColor={"white"}
                                    network={social.name}
                                    link={social.link}
                                />
                            );
                        })}
                </div>
                {photo && photo.asset && (
                    <Img
                        image={photo.asset?.gatsbyImageData}
                        className={styles.photo}
                        alt={""}
                    />
                )}
            </div>

            <div className={styles.details}>
                <H4 extend={{ styles: rules.h4s }}>{name}</H4>
                <P extend={{ styles: rules.ps }} version={"quote"}>
                    {role}
                </P>
                <P extend={{ styles: rules.ps }} version={"quote"}>
                    {organisation}
                </P>

                <div className={styles.socials}>
                    {socials &&
                        socials.map((social, index) => {
                            return (
                                <Social
                                    extend={{ styles: rules.social }}
                                    key={`profile-social-${index}`}
                                    bgColor={"white"}
                                    network={social.name}
                                    link={social.link}
                                />
                            );
                        })}
                </div>
            </div>
            <div className={styles.bioContainer}>
                <P extend={{ styles: rules.bio }} version={"medium"}>
                    {bio}
                </P>
            </div>
        </div>
    );
});

export default connectFela((props) => ({
    title: {
        allUp: {
            marginBottom: 48,
        },
    },
    container: {
        "@media (min-width: 450px) and (max-width: 850px)": {
            paddingLeft: 0,
        },
    },
}))(({ rules, styles, speakers }) => {
    return (
        <Container extend={{ styles: rules.container }} flex withContentArea4>
            <H2 extend={{ styles: rules.title }}>Our speakers</H2>
            <div>
                {speakers.map((speaker, index) => {
                    return <Speaker key={"sp-" + index} {...speaker} />;
                })}
            </div>
        </Container>
    );
});
