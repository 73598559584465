import React from "react";
import { connect as connectFela } from "react-fela";

import Container from "components/container";
import fluidValues from "utilities/fluidValues";
import calc from "utilities/calc";

export default connectFela((props) => ({
    container: {
        backgroundColor: props.bgColor,
        ...fluidValues({
            paddingRight: [20, 80],
            paddingLeft: [20, 80],
        }),
        ipadMiniAndUp: {
            paddingLeft: calc(0, 80, 768, 1440),
        },
        "@media (min-width: 1440px)": {
            paddingLeft: 80,
        },
    },
    fullContainer: {
        backgroundColor: props.bgColor,
        ...fluidValues({
            paddingRight: [20, 80],
            paddingLeft: [20, 80],
        }),
        "@media (min-width: 900px)": {
            paddingLeft: '0 !important',
        },
    },
    innerContainer: {
        display: "flex",
        flexWrap: "wrap",
        gridGap: 60,
        extend: [{
            condition: !props.withMaxWidth,
            style: {
                ipadMiniAndUp: {
                    display: "grid",
                    gridTemplateRows: `1fr`,
                    gridTemplateColumns: `1fr 1fr`,
                    gridGap: calc(35, 140, 768, 1440),
                },
            }
        },{
            condition: props.withMaxWidth,
            style: {
                "@media (min-width: 900px)": {
                    display: "grid",
                    gridTemplateRows: `1fr`,
                    gridTemplateColumns: `1fr 1fr`,
                    gridGap: calc(35, 140, 768, 1440),
                },
            }
        }],
        "@media (min-width: 1440px)": {
            gridGap: 140,
        },
    },
    column: {
        flex: '1 1 auto',
    }
}))(({ rules, styles, left, right, withMaxWidth }) => {
    return (
        <Container
            extend={{ styles: (withMaxWidth ? rules.fullContainer : rules.container) }}
            withContentArea4={withMaxWidth ? false : true}
            resetPadding
        >
            <div className={styles.innerContainer}>
                <div className={styles.column}>{left}</div>
                <div className={styles.column}>{right}</div>
            </div>
        </Container>
    );
});
