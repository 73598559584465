import React, { useContext } from "react";
import { connect as connectFela } from "react-fela";

import Container from "components/container";
import UnderlinedTitle from "components/UnderlinedTitle";
import fluidValues from "utilities/fluidValues";
import Breadcrumb from "components/Breadcrumb";
import Share from "components/Share";
import CountryContext from "context/CountryContext"

export default connectFela((props) => ({
    container: {
        ...fluidValues({
            paddingTop: [100, 220]
        }),
        backgroundColor: props.bgColor,
    },
    shareTop: {
        marginLeft: "auto",
        display: "block",
        flex: "1 0 auto",
        textAlign: "right",
        ipadMiniAndDown: {
            display: "none",
        },
    },
    shareTopMobile: {
        flexBasis: "100%",
        ipadMiniAndUp: {
            flexBasis: "calc(10 / 12 * 100%)",
        },
        ...fluidValues({ marginBottom: [20, 32] }),
        display: "none",
        ipadMiniAndDown: {
            display: "block",
        },
        "& a:first-child": {
            marginLeft: 0,
        },
    },
    title: {
        width: '100%'
    }
}))(({ category, bgColor, image, rules, styles, title, breadcrumb }) => {
  const country = useContext(CountryContext);
    return (
        <div className={styles.container}>
            <Container flex withContentArea4>
                <Share
                    extend={{ styles: rules.shareTopMobile }}
                    bgColor={bgColor}
                    url={typeof window !== "undefined" && window.location.href}
                    country={country}
                />

                <Breadcrumb
                    leftPart={breadcrumb}
                    rightPart={category}
                    extend={{ styles: rules.breadcrumbs }}
                />

                <Share
                    extend={{ styles: rules.shareTop }}
                    bgColor={bgColor}
                    url={typeof window !== "undefined" && window.location.href}
                    country={country}
                />

                {title && (
                    <div className={styles.title}>
                        <UnderlinedTitle
                            style={"neo"}
                            extend={{ styles: rules.title }}
                        >
                            {title}
                        </UnderlinedTitle>
                    </div>
                )}
            </Container>
        </div>
    );
});
