import React from "react";
import { connect as connectFela } from "react-fela";

import H2 from "components/typography/H2";
import UL from "components/typography/UL";
import P from "components/typography/P";
import fluidValues from "utilities/fluidValues";

export default connectFela((props) => ({
    container: {
        "@media (min-width: 900px)": {
            maxWidth: 498,
        }
    },
    list: {
        paddingLeft: 0,
        listStyleType: "none",
    },
    li: {
        position: "relative",
        ...fluidValues({
            paddingLeft: [48, 72],
        }),
        onBefore: {
            content: "''",
            position: "absolute",
            backgroundColor: "black",
            left: 0,
            top: "0.6rem",
            ...fluidValues({
                height: [12, 24],
                width: [12, 24],
            }),
        },
    },
    title: {
        allUp: {
            marginBottom: 24
        }
    }
}))(({ rules, styles, title, points }) => {
    return (
        <div className={styles.container}>
            <div className={styles.sectionTitleMarginTop}>
                <H2 neo extend={{styles: rules.title}}>{title}</H2>
            </div>
            <UL extend={{ styles: rules.list }}>
                {points.map((item, i) => {
                    return (
                        <P
                            tag={"li"}
                            extend={{ styles: rules.li }}
                            version={"medium"}
                            key={"list-" + i}
                        >
                            {item}
                        </P>
                    );
                })}
            </UL>
        </div>
    );
});
