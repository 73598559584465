import React from "react";
import { connect as connectFela } from "react-fela";

import { combineRules } from "fela";
import H4 from "components/typography/H4";
import H2 from "components/typography/H2";
import P from "components/typography/P";
import fluidValues from "utilities/fluidValues";

export default connectFela((props) => ({
    ps: {
        ...fluidValues({
            marginLeft: [24, 60],
        }),
        allUp: {
            marginBottom: 0,
        },
    },
    h4s: {
        ...fluidValues({
            marginLeft: [24, 60],
        }),
        allUp: {
            marginTop: 0,
        },
    },
    speaker: {
        allUp: {
            marginBottom: 0,
        },
    },
    innerContainer: {
        display: "flex",
    },
    content: {
        position: "relative",
        onBefore: {
            content: "''",
            display: "block",
            background: "black",
            position: "absolute",
            top: 0,
            bottom: 0,
            ...fluidValues({
                width: [12, 24],
            }),
        },
    },
    title: {
        allUp: {
            marginBottom: 24,
        },
        '@media (min-width: 765px) and (max-width: 900px)': {
            textAlign: 'center'
        }
    }
}))(({ rules, styles, date, time, speakers, venue }) => {
    const dateojb = new Date(date);
    const options = {
        weekday: "long", // Full name of the weekday
        day: "numeric", // Day of the month as a number
        month: "short", // Short name of the month
        year: "numeric", // Full numeric year
    };

    const formattedDate = dateojb.toLocaleDateString("en-NZ", options);
    return (
        <div className={styles.container}>
            <H2 neo extend={{styles: rules.title}}>Webinar detail</H2>

            <div className={styles.innerContainer}>
                {/* <div className={styles.bar}></div> */}
                <div className={styles.content}>
                    <P extend={{ styles: rules.ps }} version={"quote"}>
                        Date
                    </P>
                    <H4 extend={{ styles: rules.h4s }}>{formattedDate}</H4>
                    <P extend={{ styles: rules.ps }} version={"quote"}>
                        Time
                    </P>
                    <H4 extend={{ styles: rules.h4s }}>{time}</H4>
                    <P extend={{ styles: rules.ps }} version={"quote"}>
                        Venue
                    </P>
                    <H4 extend={{ styles: rules.h4s }}>{venue}</H4>
                    <P extend={{ styles: rules.ps }} version={"quote"}>
                        Speakers
                    </P>
                    {speakers.map((speaker, index) => {
                        return (
                            <H4
                                key={'speaker-' + index}
                                extend={{
                                    styles: combineRules(
                                        rules.h4s,
                                        rules.speaker,
                                    ),
                                }}
                            >
                                {speaker}
                            </H4>
                        );
                    })}
                </div>
            </div>
        </div>
    );
});
